//--------------------------------------------------------------------------------
// STYLE.SCSS ********************************************************************
// This file is the core of the CSS files.
//--------------------------------------------------------------------------------

// ------------------------
// ------ STANDARD --------
// ------------------------
@charset "UTF-8";

// ------------------------
// ------ LIBRARIES -------
// ------------------------
@import "bourbon"; // Import Bourbon Sass mixins

// ------------------------
// ----- COMPONENTS -------
// ------------------------
@import "components/reset";

//------------------------
//------- PLUGINS --------
//------------------------
@import "plugins/colorbox";
@import "plugins/themeswitcher";