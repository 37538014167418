//--------------------------------------------------------------------------------
// THEMESWITCHER.SCSS ************************************************************
// This file containes default styling for the AIM themeswitcher
//--------------------------------------------------------------------------------
.theme-switcher {
  position: fixed !important; //Logged in fix
  left: -180px;
  top: 2em;
  z-index: 2;
  @include transition(all 0.2s ease-in-out);

  p.title {
    float: right;
    position: relative;
    cursor: pointer;
    text-indent: -9999px;
    width: 37px;
    height: 37px;
    color: white;
    background: #C00C0C;

    &:after {
      content: "\002B";
      position: absolute;
      top: 0;
      left: 0;
      text-indent: 0;
      text-align: center;
      width: 100%;
      height: 35px;
      font-size: 20px;
      line-height: 37px;
      @include transition(all 0.2s ease-in-out 0.3s);
    }
  }
  form {
    float: left;
    margin: 0;
    width: 180px;

    > div {
      margin: 0;
      border: 2px solid #C00C0C;

      input {
        float: none;
        display: block;
        text-align: left;
        width: 100%;
        padding: 0 10px;
        text-transform: none;
        border-radius: 0;
        height: 35px;
        line-height: 35px;
        background: white;
        color: #2A2A2A;
        box-shadow: none;

        &:hover, &.active {
          background: #E5E5E5;
        }
        &.active {
          font-weight: 700;
        }
      }
    }
  }
}

.theme-switcher.open {
  left: 0;

  p.title:after {
    @include transform(rotate(45deg));
  }
}
