//--------------------------------------------------------------------------------
// COLORBOX.SCSS *****************************************************************
// This file containes default styling for colorbox
//--------------------------------------------------------------------------------

// general - don't touch
#colorbox,
#cboxOverlay,
#cboxWrapper {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  overflow: hidden;
}
#cboxOverlay {
  position: fixed;
  width: 100%;
  height: 100%;
}
#cboxMiddleLeft,
#cboxBottomLeft {
  clear: left;
}
#cboxContent {
  position: relative;
  overflow: hidden;
}
#cboxLoadedContent {
  overflow: visible !important;
}
#cboxLoadingOverlay,
#cboxLoadingGraphic {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
#cboxPrevious,
#cboxNext,
#cboxClose,
#cboxSlideshow {
  cursor: pointer;
  border: none;
  background: none;
}
.cboxPhoto {
  float: left;
  margin: auto;
  border: 0;
  display: block;
  max-width: none;
}
.cboxIframe {
  width: 100%;
  height: 100%;
  display: block;
  border: 0;
}
#cboxTitle {
  position: absolute;
  top: rhythm(0.5);
  left: rhythm(0.5);
  padding: rhythm(0.25);
  background: white;
}